import * as React from 'react';
import { PropsWithChildren } from 'react';

interface Props {
  id: number;
}

const Article: React.FC<PropsWithChildren<Props>> = (props) => {
  return <>{props.children}</>;
};

export { Article };
