import * as React from 'react';
import classNames from 'classnames';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {
  IconDefinition as SVGCoreIconDefinition,
  IconProp,
} from '@fortawesome/fontawesome-svg-core';
import { IconDefinition as CommonIconDefinition } from '@fortawesome/fontawesome-common-types';
import { IconDefinition as FreeRegularSVGIconDefinition } from '@fortawesome/free-regular-svg-icons';
import { IconDefinition as FreeSolidSVGIconDefinition } from '@fortawesome/free-solid-svg-icons';

import styles from './style.module.scss';

type IconDefinition =
  | SVGCoreIconDefinition
  | CommonIconDefinition
  | FreeRegularSVGIconDefinition
  | FreeSolidSVGIconDefinition
  | IconProp;
interface Props extends FontAwesomeIconProps {
  icon: IconDefinition;
}

const Icon: React.FC<Props> = (props) => {
  const { className } = props;

  return (
    <FontAwesomeIcon
      {...props}
      className={classNames(styles.icon, className)}
    />
  );
};

export { Icon };
