import * as React from 'react';
import { PropsWithChildren } from 'react';

interface Props {}

const Content: React.FC<PropsWithChildren<Props>> = (props) => {
  return <>{props.children}</>;
};

export { Content };
