import { ApolloProvider } from '@apollo/client';
import * as React from 'react';

import { client } from './client';
import { PropsWithChildren } from 'react';

const Provider: React.FC<PropsWithChildren<any>> = (props) => {
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export { Provider };
