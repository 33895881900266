import { gql } from "@apollo/client";

export const clientSchemaTypeDefs = gql`
  # leave this example!
  extend type users {
    mockedField: String
  }

  # add new definitions below this line!

  # add new definitions above this line!
`;
