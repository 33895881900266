import { InMemoryCache } from '@apollo/client/cache';

import { clientSchemaPolicies } from './schema';

// todo: modify type to leverage TypedTypePolicies + incorporate a dynamic type for mocked fields
const typePolicies = {
  ...clientSchemaPolicies,
};

export const cache = new InMemoryCache({
  typePolicies,
});
